import { useParams } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import Home from "./Home"

type RedirectParams = {
    redirectURL: string
}
export const Redirect = () => {
    const params = useParams<RedirectParams>()

    if (params.redirectURL !== undefined) {
        window.location.replace(params.redirectURL)
        return null
    }
    return (
        <SessionAuth>
            <Home />
        </SessionAuth>
    )
}